import type { ParsedUrlQuery } from 'querystring';
import type { Locale } from '@flex-mf/i18n';
import { decideLocale } from '@flex-mf/i18n';
import type { RuntimeEnv } from '@flexteam/env';
import {
  generateFlexUTMs,
  type UserProperty as AmplitudeUserProperty,
} from '@flexteam/logger';
import type { AppContext, AppInitialProps } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';

export interface InitialProps extends AppInitialProps {
  env: RuntimeEnv;
  serverSideProps: {
    locale: Locale;
    now: number;
    userAgent: string;
    amplitudeUserProperties: AmplitudeUserProperty;
    flexUTMs: ParsedUrlQuery;
  };
}

export async function getInitialProps(
  appContext: AppContext
): Promise<InitialProps> {
  const appProps = await App.getInitialProps(appContext);

  const { req } = appContext.ctx;

  const userAgent = req?.headers['user-agent'] || '';

  const env = getConfig().publicRuntimeConfig as RuntimeEnv;
  const locale = await decideLocale(appContext.ctx);

  const query = appContext.router.query;
  const flexUTMs = generateFlexUTMs(query);

  return {
    ...appProps,
    env,
    serverSideProps: {
      locale,
      now: Date.now(),
      userAgent,
      amplitudeUserProperties: {
        ...flexUTMs,
      },
      flexUTMs,
    },
  };
}
